import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import { PageParams } from "../../../types";
import Img from "gatsby-image";

import "./administration.scss";

import { InView } from "react-intersection-observer";

import Layout from "../../components/layout/layout";
import Button from "../../components/button/button";
import Hero from "../../components/hero/hero";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import { Portfolio, PortfolioNode } from "../../utils/portfolio";
import ScrollButton from "../../components/scroll-button/scroll-button";

const AdministracjaPage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const experienceItems = [
    {
      name: "Spotkanie",
      to: "/",
    },
    {
      name: "Przygotowanie wstępnej wyceny i&nbsp;draftu umowy",
      to: "/",
    },
    {
      name: "Przygotowanie specyfikacji, określenie funkcjonalności",
      to: "/",
    },
    {
      name: "Projekt graficzny",
      to: "/",
    },
    {
      name: "Programowanie",
      to: "/",
    },
    {
      name: "Modułowe wydania portalu",
      to: "/",
    },
    {
      name: "Opieka powdrożeniowa",
      to: "/",
    },
  ];

  const data = useStaticQuery(
    graphql`
      {
        project: file(
          relativePath: { eq: "sklepy-internetowe/yummypix-rwd.png" }
        ) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rwd: file(relativePath: { eq: "sklepy-internetowe/rwd.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seo: file(relativePath: { eq: "mac-mock.png" }) {
          id
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portfolio: allFile(
          filter: {
            sourceInstanceName: { eq: "portfolio" }
            extension: { eq: "mdx" }
          }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              sourceInstanceName
              relativeDirectory
              relativePath
              childMdx {
                frontmatter {
                  hero_color
                  color
                  section_bg
                  category
                  slug
                  title
                  language
                  description
                  short_description
                  created
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 900, maxHeight: 600) {
                        base64
                        src
                        aspectRatio
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                  gallery_alt
                  gallery {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        base64
                        src
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                }
                }
                body
              }
            }
          }
        }
      }
    `
  );
  const itemSlugs = ["wallers", "yummypix", "zaklady-miesne-kozlowski"];
  const items = Portfolio.getNodesByLanguage("pl", data.portfolio.group)
    .filter((node: PortfolioNode) => {
      return itemSlugs.indexOf(node.childMdx.frontmatter.slug) >= 0;
    })
    .map((node: PortfolioNode) => {
      return node.childMdx.frontmatter;
    });

  return (
    <>
      <SEO title="Administracja | Aplinet" />
      <Hero>
        <div className="container-xs header-padding">
          <h1>
            Administracja i&nbsp;opieka nad produktami cyfrowymi
          </h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Dbamy o prawidłowe działanie aplikacji, systemów oraz hostingu.
            </h2>
          </div>
          <h4>
            W&nbsp;naszych realizacjech, kliencji docenili: niezawodność, bezawaryjność, szybkość reakcji.
          </h4>
        </div>
        <ScrollButton style={{
          position: "absolute",
          left: "50%",
          bottom: "5%",
          transform: "translateX(-50%)"
        }} to={scrollRef} />
      </Hero>

      <section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Co zyskasz"
          headerText="Dlaczego warto posiadać opiekę administracyjną w&nbsp;naszej firmie"
        />
        <p>
          Dostępność do sprawnego serwisu usług internetowych, jest konieczny w dzisiejszych czasach.
        </p>
        {/* -----------------------------------------` */}
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.project.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
            <p className="align-center">
              <strong>
                Stworzyliśmy 2016 roku Cantora Systems - software house, którego
                zadaniem jest udostępnienie specjalistów do wymagających zadań
                programistycznych.
              </strong>
            </p>
            <div style={{ textAlign: "center" }}>
              <Button color="primary" type="default" to="/" language="pl">
                Dowiedz się więcej o&nbsp;Cantora Systems
              </Button>
            </div>
          </div>
          <div className="container">
            <HeaderBig
              tag="h2"
              span="01 Kompleksowość"
              headerText="Stworzymy wszystkie funkcjonalności które zaplanujesz"
            />
            <p>
              Portale internetowe muszą spełniać przynajmniej dwa podstawowe
              zadania: muszą byś prose w&nbsp;obsłudze i&nbsp;funkcjonalne by
              spełnić stawiane im cele.
            </p>
            <p>
              <strong>
                Projektując portal w&nbsp;naszej agencji, jesteśmy pewni że:
              </strong>
            </p>
            <ul>
              <li>przygotujemy wszystkie wymagania które zaplanujesz</li>
            </ul>
          </div>
        </StickyContainer>
        {/* -----------------------------------------` */}
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container">
            <HeaderBig
              tag="h2"
              span="02 Skalowalność"
              headerText="Stawiamy na modułowość i&nbsp;skalowalność"
            />
            <p>
              Tworząc oprogramowanie, dzielimy zadzania na funkcjonalne moduły,
              które oddajemy klientom w&nbsp;trakcie 2-tygodniowych sprintów.
            </p>
            <p>
              Dzięki takiej organizacji pracy, nasi klienci aktywnie uczestniczą
              w&nbsp;procesie tworzenia docelowego produktu. Mogą szybciej
              reagować na blędne założenia projektu i&nbsp;testować
              funkcjonalności etapami, co zanacznie przyspiesza czas realizacji.
            </p>
            <p>
              <strong>Nasza firma kładzie duży nacisk na:</strong>
            </p>
            <ul>
              <li>możliwość rozwoju i&nbsp;skalowalność portalu,</li>
              <li>
                aktywny kontakt z&nbsp;klientem w&nbsp;procesie tworzenia
                witryny.
              </li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.seo.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
        </StickyContainer>
        {/* -----------------------------------------` */}
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container">
            <HeaderBig
              tag="h2"
              span="03 UX i&nbsp;UI"
              headerText="Intuicyjność obsługi i&nbsp;łatwa administracja to podstawa"
            />
            <p>
              Zarządzanie za pomocą panelu administracyjnego i&nbsp;interfejs
              skierowany na odczucia użytkowników. Funkcje które ułatwiają
              i&nbsp;zachęcają do użytkowania.
            </p>
          </div>
        </StickyContainer>
        {/* -----------------------------------------` */}
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div className="container">
            <HeaderBig
              tag="h2"
              span="04 Przyjazny interfejs"
              headerText="Dedykowany design - atrakcyjny wygląd"
            />
            <p>
              Możemy pomyśleć o&nbsp;testach na użytkownikach lub przygotować
              nie wiem co jeszcze zebyś pan zamówił u&nbsp;nasz swój pportal
              internetowy
            </p>
            <p>Ważny jest to co jest ważne</p>
            <ul>
              <li></li>
            </ul>
          </div>
          <div data-sticky className="">
            <Img
              fluid={data.seo.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
            <div className="align-center">
              <Button color="primary" type="default" to="/" language="pl">
                Dowiedz się więcej o&nbsp;Fennec CMS
              </Button>
            </div>
          </div>
        </StickyContainer>
        {/* -----------------------------------------` */}
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.rwd.childImageSharp.fluid}
              className="image-test"
              alt="Bridge"
              title="Bridge"
            />
          </div>
          <div className="container">
            <HeaderBig
              tag="h2"
              span="05 Administracja"
              headerText="Pełna opieka po wdrożeniowa"
            />
            <p>Administracja</p>
          </div>
        </StickyContainer>
        {/* -----------------------------------------` */}
        <div className="align-center">
          <Button color="primary" type="inversed" to="/kontakt" language="pl">
            Darmowa wycena portalu www
          </Button>
        </div>
      </section>

      <section className="container">
        <HeaderBig
          tag="h2"
          span="Strony internetowe"
          headerText="Kilka naszych realizacji"
        />
      </section>

      <PortfolioList items={items}></PortfolioList>

      <section className="container">
        <div className="align-center">
          <Button
            color="primary"
            type="inversed"
            to="/realizacje"
            language="pl"
          >
            Zobacz wszystkie realizacje
          </Button>
        </div>
      </section>

      <section className="container">
        <HeaderBig
          tag="h2"
          span="Profity aplikacji webowych"
          headerText="Po co tworzyć profesjonalną aplikację internetową"
        />
        <div className="r :w :c-4 :p">
          <div className="padding">
            <HeaderBig
              tag="h3"
              span="01"
              headerText="Aplikacje B2B i B2C, integracje"
            />
          </div>
          <div className="padding">
            <HeaderBig
              tag="h3"
              span="02"
              headerText="Systemy zarządzania zamówieniami"
            />
          </div>
          <div className="padding">
            <HeaderBig tag="h3" span="03" headerText="Platformy e-lerningowe" />
          </div>
        </div>
      </section>

      {/* -----------------------------------------` */}

      <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
        <div data-sticky className="container">
          <HeaderBig
            tag="h2"
            span="Przegieg współpracy"
            headerText="Etapy tworzenia administracji"
          />
          <p>
            Przed złożeniem zapytania ofertowego, postaraj się odpowiedzieć na
            pytanie "po jaką usługę przychodzisz do agencji". Zastanów się,
            jakiego zakresu kompetencji potrzebujesz, w&nbsp;jakim obszarze mamy
            wspierać Twój pomysł? Podsumuj swoje oczekiwania i&nbsp;przygotuj
            pytania.
          </p>
          <p>
            Aby ułatwić to zadanie, przygotowaliśmy etapy prawidłowo
            zaplanowanej współpracy przy tworzeniu administracji.
          </p>
          <div style={{ textAlign: "center" }}>
            <Button color="primary" type="default" to="/kontakt" language="pl">
              Wyceń swóją aplikację
            </Button>
          </div>
        </div>
        <ul className="experience count">
          {experienceItems.map((item, index) => {
            return (
              <InView key={index} rootMargin="-49% 0px -49% 0px">
                {({ inView, ref, entry }) => (
                  <li ref={ref} className={inView ? "active" : ""}>
                    <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                  </li>
                )}
              </InView>
            );
          })}
        </ul>
      </StickyContainer>

      {/* -----------------------------------------` */}
      <CallFooter />
    </>
  );
};

export default AdministracjaPage;
